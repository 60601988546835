#pop_close {
  text-decoration: underline;

  position: absolute;
  bottom: 20px;
  left: center;
}

#pop_close:hover {
  color: #0000ee;
  cursor: pointer;
}