#header {
  width: 100%;
}

#header_top {
  display: flex;
  align-items: center;
  height: 20px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

#header_top_logo {
  flex: 1 0 auto;
}

.header_menu_b {
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.header_menu_b:hover {
  text-decoration: underline;
  color: #0000ee;
}

#header_mid {
  width: 100%;
  height: 200px;

  box-sizing: border-box;

  border: solid 2px var(--color-high2);

  display: flex;
  align-items: center;

  background-image: url('/public/images/logo.png');
  background-position: center;

  position: relative;

  margin-bottom: 10px;
}

#header_menu {
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
}

#header_menu>div {
  cursor: pointer;
  font-size: 14px;

  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

#header_menu>div:hover {
  text-decoration: underline;
}