#main {
  display: flex;
  width: 100%;
}

/* *----- MAIN LEFT -----* */

#main_left {
  display: flex;
  flex-direction: column;

  width: 200px;
  margin-right: 10px;
}

#main_left_menu_banner_end {
  width: 100%;
  box-sizing: border-box;
  border: solid 2px var(--color-high2);
}

#main_left_menu_banner_end>img {
  width: 100%;
}

.menu {
  background-color: #fff;

  box-sizing: border-box;

  border: solid 2px var(--color-high2);

  margin-bottom: 10px;

  padding: 10px 5px;
}

.login_form {
  display: flex;
  align-items: center;

  margin-bottom: 10px;

  width: 100%;
}

.login_form_t {
  box-sizing: border-box;
  width: 70px;
  font-size: 12px;
  padding-right: 10px;
  text-align: end;
}

.login_form_input {
  flex: 1 0 0;
  overflow: hidden;
  margin-right: 15px;
}

.login_form_input>input {
  box-sizing: border-box;
  width: 100%;
}

.login_btn_box {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 10px;
}

.login_btn {
  font-size: 9pt;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 2px;
  border: solid 1px #000;

  background: linear-gradient(90deg, rgba(11, 23, 37, 1) 0%, rgba(211, 211, 207, 1) 1%, rgba(211, 211, 207, 1) 50%, rgba(240, 240, 238, 1) 99%, rgba(11, 23, 37, 1) 100%);
  cursor: pointer;

  user-select: none;
}

.login_no {
  font-size: 11px;
  margin-top: 10px;
  padding-left: 12px;
}

.login_no>span {
  text-decoration: underline;
}

.login_no:hover,
.main_left_menu>span:hover {
  cursor: pointer;
  color: var(--color-high1);
}

#login_true {
  padding: 5px;
  font-size: 12px;
}

#login_true>b {
  font-size: 14px;
}

#main_left_menu {
  font-size: 12px;
}

.main_left_menu {
  display: flex;
  align-items: center;

  box-sizing: border-box;

  padding: 3px 5px;
}






/* !----------------------! */
/* *----- MAIN RIGHT -----* */
/* !----------------------! */

#main_right {
  display: flex;
  flex-direction: column;

  flex: 1 0 0;
}

#main_right_banner {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

#main_right_banner>div {
  height: 200px;
}

#main_right_banner_1 {
  width: calc(50% - 5px);
  box-sizing: border-box;
  margin-right: 10px;
  border: solid 2px var(--color-high2);
}

#main_right_banner_2 {
  width: calc(50% - 5px);
  box-sizing: border-box;
  border: solid 2px var(--color-high2);
}

#main_right_title {
  margin-bottom: 20px;
  background-color: var(--color-high2);
  color: #fff;
  font-weight: 600;
  padding: 10px 5px;
}

.bags_row {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 20px;
}

.bags_col {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 250px;
  box-sizing: border-box;
}

.bags_col:hover,
.bags_col:hover .b_title {
  cursor: pointer;
  text-decoration: underline;
}

.b_title {
  font-weight: 600;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
}

.b_price {
  margin-bottom: 20px;
}

.bags_col.cent {
  margin: 0 20px;
}

.b_img {
  box-sizing: border-box;
  width: 250px;
  height: 250px;
  border: solid 2px var(--color-high2);

  overflow: hidden;

  margin-bottom: 10px;
}

.b_img>img {
  width: 100%;
  height: 100%;
}

#main_left_banner {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;
}

.mlb_box {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;

  border: solid 2px var(--color-high2);

  overflow: hidden;

  margin-bottom: 5px;

  cursor: pointer;
}

.mlb_box>img {
  height: 100%;
}

.mlm_arr {
  font-size: 10px;
  color: var(--color-high1);

  margin-right: 5px;
}

#header_top_logo {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-high2);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-high3);
}

#mr_order {
  margin-top: 20px;
  display: flex;
  font-family: '돋움';
}

#mr_order_image {
  width: 250px;
  height: 250px;

  box-sizing: border-box;
  border: solid 2px var(--color-high2);

  overflow: hidden;
}

#mr_order_image>img {
  height: 100%;
}

#mr_order_r {
  display: flex;
  flex-direction: column;

  margin-left: 20px;
  flex: 1 0 0;

  box-sizing: border-box;
  border-right: solid 2px var(--color-high2);
}

#mr_order_r_name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.mr_order_row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.mor_l {
  display: flex;
  align-items: center;
  width: 100px;
}

.mor_colon {
  margin-right: 10px;
}

.mor_love {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12px;
  height: 12px;
  background-color: #fff;

  border-radius: 50%;
  overflow: hidden;

  margin-right: 5px;
}

#mr_order_btn {
  margin-top: 20px;
  margin-right: 250px;
  display: flex;
  align-items: center;

  margin-bottom: -5px;
}

#order_help {
  display: flex;
  flex-direction: column;

  font-size: 12px;
}

#order_help>p {
  margin: 5px 0;
}

.oh_title {
  margin-top: 200px;
  background-color: var(--color-high2);
  color: #fff;
  font-weight: 600;
  padding: 10px 5px;
  margin-bottom: 10px;
  font-size: 16px;
}

.oh_index {
  background-color: var(--color-high3);
  width: fit-content;
  font-size: 14px;
  color: #000;

  border-radius: 14px;
  padding: 2px 10px;

  margin-top: 50px;
  margin-bottom: 15px;
}

.mp_form {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  margin-bottom: 50px;

  box-sizing: border-box;
}

.mp_input {
  display: flex;
  align-items: center;
  width: 100%;
}

.mp_input>input {
  width: 100%;
  box-sizing: border-box;
  padding: 4px 6px;
}

.mp_name {
  margin-bottom: 10px;
}


#mr_payment {
  box-sizing: border-box;
  border: solid 2px var(--color-high2);
  /* border-right: solid 2px var(--color-high2); */
  padding: 20px;
}

#mr_payment_ok_box {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.mr_payment_ok {
  flex: 0 100px;
}

.bags_status {
  margin-left: 5px;
  height: 16px;
}

.order_list {
  box-sizing: border-box;
  border: solid 2px var(--color-high2);
  margin-top: -10px;
}

.ol_row {
  display: flex;
  align-items: center;

  background-color: #fff;

  border-bottom: solid 1px var(--color-high4);
}

.ol_row>div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  height: 100%;

  box-sizing: border-box;
  border-right: solid 1px var(--color-high4);

  font-size: 12px;
}

.ol_row>.end {
  border-right: none !important;
}

.ol_header {
  background-color: #ccd9f6;
  font-weight: 600;
  border-bottom: double 3px var(--color-high4);
}

.ol_header>.ol_row_1 {
  color: #000;
  text-decoration: none;
}

.ol_able {
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
}

/* *---- ORDER LIST ----* */
.ol_row_1 {
  width: calc(10% * 2);
}

.ol_row_2 {
  width: calc(10% * 2);
}

.ol_row_3 {
  width: calc(10% * 2);
}

.ol_row_4 {
  width: calc(10% * 1);
}

.ol_row_5 {
  width: calc(5% * 3);
}

.ol_row_6 {
  width: calc(5% * 3);
}

/* *---- REVIEW LIST ----* */
.ol_row_7 {
  width: calc(10% * 1);
}

.ol_row_8 {
  flex-direction: column;
  align-items: flex-start !important;
  width: calc(10% * 6);
}

.ol_header>.ol_row_8 {
  align-items: center !important;
}

.ol_row_8>p {
  line-height: 15px;
  height: 15px;
  margin: 2.5px 0 2.5px 10px;
}

.ol_row_9 {
  width: calc(10% * 1.25);
}

.ol_row_10 {
  width: calc(10% * 1);
}

.ol_row_11 {
  width: calc(10% * 0.75);
}

#num_list {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  margin-top: 20px;
}

.num_btn {
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  padding: 0 5px;
}

.num_btn>span {
  font-size: 15px;
  text-align: center;
  width: 20px;
  letter-spacing: -.5px;

  cursor: pointer;
  text-decoration: underline;
  color: #0000ee;
}

.num_btn.num_now>span {
  font-weight: 600;
  color: #000;
  text-decoration: none;
  cursor: default;
}

#mr_order_pic_box {
  display: flex;
  flex-direction: column;
}

#mr_order_pic_box>.mr_order_pic {
  width: 500px;
  height: 500px;
  margin-bottom: 10px;
  box-sizing: border-box;

  border: solid 2px var(--color-high2);
  overflow: hidden;
}

.mr_order_pic>img {
  width: 100%;
}